$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

$notosans-fontface-path: "~notosans-fontface/fonts";
@import "~notosans-fontface/scss/notosans-fontface-allweight";

$hc-icons-font-path: "~@healthcatalyst/cashmere/hcicons";
@import "~@healthcatalyst/cashmere/hcicons/hcicons";

body {
  margin: 0px;
}

svg {
  .dirty text {
    cursor: pointer;
    text-decoration: underline;
  }
}

.inline-oxford-list {
  display: inline;
  margin: 0;
  padding: 0;
  li {
    display: inline;
    &::before {
      content: ", "
    }
    &:first-child::before {
      content: ""
    }
    &:last-child::before {
      content: ", and "
    }
  }
}
